import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { useState } from 'react';
import { useGetExchangeAccountsQuery } from '@shared/api/exchange';
import Button from '@shared/ui/buttons/Button';
import { IsDefined } from '@shared/lib/Helpers';
const ConnectExchangeButton = () => {
    const [isOpen, setIsOpen] = useState(false);
    const theme = useTheme();
    const { t } = useTranslation();
    const { data: exchangesList, } = useGetExchangeAccountsQuery();
    if (IsDefined(exchangesList) && exchangesList.length !== 0) {
        return null;
    }
    const handleSidebarOpen = () => setIsOpen(true);
    return (_jsx(_Fragment, { children: _jsx(Button, { style: {
                minHeight: 'unset',
                padding: `0px ${theme.spacing_sizes.xs}px`,
                fontSize: 12,
            }, size: 'small', children: t('profile.connect_exchange', { ns: 'common' }) }) }));
};
export default ConnectExchangeButton;
